import React from "react";
import Image from "next/image";
import Logo from "../../public/images/advice-premium-care-logo.png";

export const DarkLogo = ({ height }) => {
    return (
        <div className="flex align-middle justify-center">
            <Image
                src={Logo}
                alt="Advice Premium Care Logo"
                height={height || 150}
                className="my-2"
            />
            {/* <h1 className="self-center text-base sm:text-2xl py-0 font-bold uppercase">ดูแลจอคุณ</h1> */}
        </div>
    );
};
