import React, { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import UserContext from "../context/userContext";
import { DarkLogo } from "../components/Logo/DarkLogo";

export default function LandingPage() {
  const router = useRouter();

  const { sn } = router.query;

  const { backendTokens, isAuthenticating, verified } = useContext(UserContext);
  
  useEffect(() => {
    if (!isAuthenticating && backendTokens) {
      if (sn) {
        localStorage.setItem('sn', sn);
      } else {
        localStorage.removeItem('sn');
      }

      if (verified) {
        router.replace("/home");
      } else {
        router.replace("/register");
      }
    }
  }, [router, backendTokens, isAuthenticating, verified, sn]);


  return (
    <div className="h-full bg-sky-500">
      <section
        id="header"
        className="h-screen overflow-y-auto text-neutral-800 flex items-center justify-center"
      >
        <div className="container lg:px-40 md:px-10 sm:px-2 -mt-16 pb-2 text-center animate-pulse">
          <DarkLogo height={120} />
          <p className="font-heading text-white mb-0">
            ครอบคลุมทุกการดูแลที่มากกว่า
          </p>
        </div>
      </section>
    </div>
  );
}
